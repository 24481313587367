const longGames = [
  { id: '32', gTitle: 'Castles of Mad King' },
  { id: '7', gTitle: 'Egizia' },
  { id: '28', gTitle: 'Medici' },
  { id: '12', gTitle: 'Puerto Rico' },
  { id: '13', gTitle: 'Ra' },
  { id: '18', gTitle: 'Stone Age' },
  { id: '21', gTitle: 'Vegas Showdown' },
];

export default longGames;
