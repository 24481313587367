import './Report20XX.css';

const report2023 = () => {
  return (
    <div className='Report20XX_wrapper'>
      {/* THE CHAMP */}
      <div className='Report20XX_header'>Consistency Is King</div>
      <div className='Report20XX_champPlaque'>
        <img
          className='Report20XX_champPlaquePic'
          src='/images/2023/2023Plaque.JPG'
          alt='2023 Plaque'
        />
      </div>
      <div className='Report20XX_champTextShrink'>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;Following upon the "Year of Firsts," 2023 saw
          Perseverance and Consistency rewarded instead.
        </p>
        <p>
          The field was all familiar faces--once again no room for any new Toads
          this year. Rodney tagged out for this season with new-job obligations,
          but Randy rejoined us to fill the spot.
        </p>
        <p>
          Last year's Champ (Kyle) had a precipitous fall, seemingly making a
          run for the Mug. But Aaron struggled mightily this year, and secured
          that coveted coffee container (which I'm sure he will treasure
          always??).
        </p>
        <p>
          The battle to make the Final table was fierce as always, with Antony
          coming up just one point short, after sitting in the Top Toad seat for
          much of the afternoon (it's always someone, every year). Indeed a
          whole cluster of Saccentis massed right around the slots just BELOW
          the slots that matter, including the Shirtless One.
        </p>
        <p>
          Returning to the Toad meant returning to the Final for Randy.
          Meanwhile Rob, an old veteran of this competition, returned to form in
          '23 after a somewhat surprising absence from the Final in recent
          years.
        </p>
        <p>
          Perseverance? That's Chris Wildes, who has been all over the standings
          over the years (and even owns a Mug!). Finally he breaks through in
          '23. Consistency? That's Andrew, who has made the Final every single
          year he has made it to the Toad, but only managed the plaque in '21.
          This year he ends an impressive streak for the tourney (Six Years, Six
          Winners), becoming the first Toad to reach the Top for a second time.
          It seems entirely correct that our first player to achieve this is
          Andrew, who sits proudly atop our{' '}
          <a href='/stats'>All-Time standings</a> where he belongs!
        </p>
      </div>
      <div className='Report20XX_champPic'>
        <img
          className='Report20XX_champPlayerPic'
          src='/images/2023/2023Champ.JPG'
          alt='Andrew Emerick displays plaque'
        />
      </div>
      <div className='Report20XX_padding' />
      {/* THE TOADS */}
      <div className='Report20XX_header'>The Toads of 2023</div>
      <div className='Report20XX_toads'>
        <img
          className='Report20XX_toadsPic'
          src='/images/2023/2023Group.JPG'
          alt='The 2023 Top Toads Group'
        />
      </div>
      <ul className='Report20XX_toadsText'>
        <li>Aaron Blair (MD)</li>
        <li>Randy Buehler (WA)</li>
        <li>Andrew Emerick (CT)</li>
        <li>Marcy Morelli (PA)</li>
        <li>Rob Murray (NJ)</li>
        <li>Antony Saccenti (MD)</li>
        <li>Donte Saccenti (MD)</li>
        <li>Preston Saccenti (MD)</li>
        <li>Scott Saccenti (MD)</li>
        <li>Kyle Smith (PA)</li>
        <li>Dalton Versak (PA)</li>
        <li>Chad Weaver (PA)</li>
        <li>Chris Wildes (DC)</li>
        <li>Chris Yaure (PA)</li>
        <li>Joe Yaure (DE)</li>
        <li>Eugene Yee (MD)</li>
      </ul>
      <div className='Report20XX_padding' />
      {/* THE GAMES */}
      <div className='Report20XX_header'>The Games of 2023</div>
      <ul className='Report20XX_gamesText'>
        <li>7 Wonders</li>
        <li>Alhambra</li>
        <li>Carcassonne</li>
        <li>Castles of Mad King Ludwig</li>
        <li>Chicago Express</li>
        <li>Coloretto</li>
        <li>Egizia</li>
        <li>For Sale</li>
        <li>Ingenious</li>
        <li>Istanbul</li>
        <li>Las Vegas</li>
        <li>Luxor</li>
        <li>Medici</li>
        <li>Navegador</li>
        <li>Puerto Rico</li>
        <li>Ra</li>
        <li>Race for the Galaxy</li>
        <li>Saint Petersburg</li>
        <li>Speicherstadt</li>
        <li>Splendor</li>
        <li>Stone Age</li>
        <li>Thurn & Taxis</li>
        <li>Ticket to Ride</li>
        <li>Vegas Showdown</li>
      </ul>
      <div className='Report20XX_games'>
        <img
          className='Report20XX_gamesPic'
          src='/images/2023/2023GreatWestern.JPG'
          alt='Wide shot of several games being played at Top Toad'
        />
      </div>
      <div className='Report20XX_extraPics'>
        <img
          className='Report20XX_extraPic1'
          src='/images/2023/2023Mars.jpg'
          alt='Antony and Donte Saccenti, and Chad Weaver play Terraforming Mars'
        />
        <img
          className='Report20XX_extraPic2'
          src='/images/2023/2023Speicherstadt.JPG'
          alt='Eugene Yee, Chris Yaure, Andrew Emerick and Aaron Blair play The Speicherstadt'
        />
        <img
          className='Report20XX_extraPic3'
          src='/images/2023/2023Splendor.JPG'
          alt='Scott Saccenti, Chris Wildes, Preston Saccenti and Marcy Morelli play Splendor'
        />
        <img
          className='Report20XX_extraPic4'
          src='/images/2023/2023LasVegas.JPG'
          alt='Antony, Linsey and Donte Saccenti, and Andrew Emerick and Dalton Versak play Las Vegas'
        />
        <img
          className='Report20XX_extraPic5'
          src='/images/2023/2023AntonyChad.JPG'
          alt='Antony Saccenti and Chad Weaver play Ticket to Ride'
        />
        <img
          className='Report20XX_extraPic6'
          src='/images/2023/2023EugeneRandy.JPG'
          alt='Eugene Yee and Randy Buehler'
        />
        <img
          className='Report20XX_extraPic7'
          src='/images/2023/2023Dalton.JPG'
          alt='Dalton Versak plays Ra'
        />
        <img
          className='Report20XX_extraPic8'
          src='/images/2023/2023ChadChris.JPG'
          alt='Chad Weaver and Chris Wildes play Ark Nova'
        />
        <img
          className='Report20XX_extraPic9'
          src='/images/2023/2023Ticket.JPG'
          alt='Marcy Morelli and Chris Wildes play Ticket to Ride'
        />
        <img
          className='Report20XX_extraPic10'
          src='/images/2023/2023Ingenious.JPG'
          alt='Donte Saccenti, Kyle Smith, Eugene Yee and Joe Yaure play Ingenious'
        />
        <img
          className='Report20XX_extraPic11'
          src='/images/2023/2023RandySleep.JPG'
          alt='Randy Buehler asleep on a couch'
        />
        <img
          className='Report20XX_extraPic12'
          src='/images/2023/2023RobDonte.JPG'
          alt='Kyle Smith, Rob Murray and Donte Saccenti play Terraforming Mars'
        />
        <img
          className='Report20XX_extraPic13'
          src='/images/2023/2023GroupGag.JPG'
          alt='2023 Top Toad Group Gag Shot'
        />
        <img
          className='Report20XX_extraPic14'
          src='/images/2023/2023FinalFour.JPG'
          alt='The 2024 Top Toad Finalists, Randy Buehler, Rob Murray, Chris Wildes and Andrew Emerick'
        />
      </div>
    </div>
  );
};

export default report2023;
