import './About.css';

const about = () => {
  return (
    <div className='About_wrapper'>
      <h1 className='About_header'>Our History</h1>
      <p>
        <img
          className='About_housePic'
          src='/images/home.jpg'
          alt='Saccenti home exterior'
        />
        &nbsp;&nbsp;&nbsp;&nbsp;Top Toad began as an idea to have a compliment
        to my family's annual "Frog Days" boardgame marathon day. My friend
        Gregg Smith and I began Frog Days nearly a decade ago--a great
        open-gaming day we hosted at my place--bringing about 40 of our gaming
        friends together for a relaxing get-together.
      </p>
      <p className='About_casualParagraph'>
        <img
          className='About_toadPic'
          src='/images/toptoad.png'
          alt='cartoon toad'
        />
        &nbsp;&nbsp;&nbsp;&nbsp;Casual gaming is super-cool, but still, we are
        competitive gamers in my family -- regular attendees at the premier
        competitive EuroGame tournament conventions like WBC, PrezCon and
        EuroQuest. I wanted to host something more focused, and bring together
        those among my gaming friends that are similarly-minded. So Top Toad was
        born.
      </p>
      <div className='floatClear'></div>
      <h1 className='About_header'>The Details</h1>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;The idea is that all the competitors will be
        tested on their mastery of a limited set of Eurogames. An upcoming model
        uses 24 games, grouped by length (8 long, 8 medium, and 8 short). The
        tournament is run using a software program that I wrote that manages the
        event. Prior to arriving, players will have already ranked the field of
        games in order of their personal preference, and are permitted to use up
        to three vetoes to avoid games they really would prefer not to play.
        Tournament points are awarded to players for not using their vetoes!
      </p>
      <br />
      <p>
        <img
          className='About_plaquePic'
          src='/images/2019/Plaque2019.jpg'
          alt='cartoon plaque'
        />
        &nbsp;&nbsp;&nbsp;&nbsp;All through the day, the program seats the
        participants at a table randomly, then asks the question: "Who is the
        Tadpole (player doing the worst at that moment) at this table?" Then,
        beginning at the top of the list of THAT player's rank of preferred
        games, the program checks to make sure...A: No one at the table vetoed
        that game, and...B: No one at the table has already played that game
        earlier in the day. And the program works its way down the Tadpole's
        preferred list until it finds an acceptable match.
      </p>
      <br />
      <p>
        <img
          className='About_shirtPic'
          src='/images/Shirts-webfriendly.jpg'
          alt='top toad t-shirts'
        />
        Over the course of the day, there will be nine games played by all
        competitors (three short, three medium, three long). Points are awarded
        in descending value for finishing first, second and even third.
        Everything matters at Top Toad! At the conclusion of the ninth game, the
        top four players (based on points earned) then get to play in the
        winner-take-all Final.
      </p>
      <br />
      <p>
        <img
          className='About_mugPic'
          src='/images/toadmug.jpg'
          alt='Tadpole mug'
        />
        &nbsp;&nbsp;&nbsp;&nbsp;Well...the winner doesn't take ALL. All four
        Finalists get a t-shirt. And there is even a prize for finishing last :)
      </p>
      <div className='floatClear'></div>
    </div>
  );
};

export default about;
