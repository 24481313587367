import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import axios from 'axios';

import columnsFromBackend from './columnsFromBackend';
import PrefDropdown from './PrefDropdown';

import './GamePreferences.css';

function GamePreferences() {
  const history = useHistory();

  const [columns, setColumns] = useState(columnsFromBackend);

  const [playerDropdownData, setPlayerDropdownData] = useState([]);
  const [selectedPlayerObject, setSelectedPlayerObject] = useState(null);

  useEffect(() => {
    axios
      .get('https://www.toptoads.com/TopToadsAPI/GetPlayerDropdown')
      .then((response) => {
        setPlayerDropdownData(response.data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDragEnd = (result, columns, setColumns) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns[source.droppableId];
      const destColumn = columns[destination.droppableId];
      const sourceItems = [...sourceColumn.items];
      const destItems = [...destColumn.items];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...sourceColumn,
          items: sourceItems,
        },
        [destination.droppableId]: {
          ...destColumn,
          items: destItems,
        },
      });
    } else {
      const column = columns[source.droppableId];
      const copiedItems = [...column.items];
      const [removed] = copiedItems.splice(source.index, 1);
      copiedItems.splice(destination.index, 0, removed);
      setColumns({
        ...columns,
        [source.droppableId]: {
          ...column,
          items: copiedItems,
        },
      });
    }
  };

  const submitHandler = () => {
    //first a little validation
    //make sure user selected a name from the dropdown
    if (selectedPlayerObject == null) {
      alert('You have to select your name');
    } else {
      for (let j = 1; j < 5; j++) {
        for (let i = 0; i < columns[j].items.length; i++) {
          let someData = {
            personID: selectedPlayerObject.playerID,
            gameID: parseInt(columns[j].items[i].id),
            eventID: 8 /* note this is hard-coded */,
            preferenceScore: j === 4 ? 0 : i + 1,
            veto: j === 4 ? true : false,
          };
          axios.post(
            `https://www.toptoads.com/TopToadsAPI/SavePlayerPreferences`,
            someData
          );
        }
      }
      history.push(`/ThankYou`);
    }
  };

  const refreshHandler = () => {
    window.location.reload();
  };

  return (
    <div className='Wrapper'>
      <div className='Header'>2024 Top Toad Game Preferences</div>
      <div className='SelectPlayer'>
        <div className='Instructions'>
          Instructions: First, Select YOUR name from the dropdown menu.
          <br />
          Then arrange the games below to your personal preference. Higher means
          you like it better. Optionally, you may drag ONE game into the Veto
          box. When things are how you like, click the "SAVE CHOICES" button.
        </div>
        <div className='DropdownContainer'>
          <PrefDropdown
            prompt='Select YOUR name...'
            options={playerDropdownData}
            id='playerID'
            label='nameCombo'
            value={selectedPlayerObject}
            onChange={(val) => setSelectedPlayerObject(val)}
          />
        </div>
      </div>
      <DragDropContext
        onDragEnd={(result) => onDragEnd(result, columns, setColumns)}
      >
        {Object.entries(columns).map(([columnId, column], index) => {
          return (
            <div className='DNDWrapper' key={columnId}>
              <h2>{column.name}</h2>
              <div className='DropArea'>
                <Droppable droppableId={columnId} key={columnId}>
                  {(provided, snapshot) => {
                    return (
                      <div
                        className='DroppableItemsWrapper'
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {column.items.map((item, index) => {
                          return (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provided, snapshot) => {
                                return (
                                  <div
                                    className={`${
                                      columnId === '4'
                                        ? 'DraggableItemVetoed'
                                        : 'DraggableItem'
                                    }`}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    <img
                                      src={`/images/game-boxes/${item.id}.jpg`}
                                      alt='gamebox'
                                      className='GameBox'
                                    />
                                    <div className='GameTitle'>
                                      {item.gTitle}
                                    </div>
                                  </div>
                                );
                              }}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    );
                  }}
                </Droppable>
              </div>
            </div>
          );
        })}
      </DragDropContext>
      <button className='SubmitButton' onClick={submitHandler}>
        SAVE CHOICES
      </button>
      <div className='RefreshButtonWrapper'>
        <button className='RefreshButton' onClick={refreshHandler}>
          Restart
        </button>
      </div>
    </div>
  );
}

export default GamePreferences;
