import './Home.css';

const home = () => {
  return (
    <div className='Home_wrapper'>
      {/* <!--- ATTEMPT IOS FIX --> */}
      <div className='IOS_landing'>
        <div className='IOS_wrap'>
          <div className='IOS_inner'></div>
        </div>
      </div>
      <div className='Home_scrollPanel'>
        <div className='centerText slackey Home_scrollPanelHeader'>
          So, What You Wanna See?
        </div>
        <div className='Home_scrollDown slackey'>(scroll down!)</div>
        {/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        {/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        {/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        {/* IMAGE AND TEXT LINKS */}
        {/* UPCOMING */}
        <div className='Home_upcoming centerText'>
          <a href='/upcoming'>
            <img
              src='/images/toadshield.png'
              alt='cartoon shield'
              className='Home_upcomingPic'
            ></img>
          </a>
          <div className='Home_upcomingText centerText slackey'>
            <a href='/upcoming'>Upcoming Event Details</a>
          </div>
        </div>
        {/* STATS */}
        <div className='Home_stats centerText'>
          <a href='/stats'>
            <img
              src='/images/toadchart.png'
              alt='cartoon chart'
              className='Home_statsPic'
            ></img>
          </a>
          <div className='Home_statsText centerText slackey'>
            <a href='/stats'>All-Time Stats</a>
          </div>
        </div>
        {/* REPORTS */}
        <div className='Home_reports centerText'>
          <a href='/reports'>
            <img
              src='/images/toadtrophy.png'
              alt='cartoon trophy'
              className='Home_reportsPic'
            ></img>
          </a>
          <div className='Home_reportsText centerText slackey'>
            <a href='/reports'>Annual Reports</a>
          </div>
        </div>
        {/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        {/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        {/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        {/* ABOUT US */}
        <div className='Home_groupPic'>
          <img
            src='/images/2022/2022GroupGag.jpg'
            alt='2022 Toads Gag Pic'
            className='Home_groupPicImage'
          ></img>
        </div>
        <div className='Home_groupText'>
          <h3>
            <span style={{ fontFamily: 'slackey' }}>Top Toad is...</span>
          </h3>
          <p>
            ... a private boardgaming tournament that brings together a special
            group of competitive gamers for one long weekend of games and glory.
          </p>
        </div>
        <div className='Home_toadPic'>
          <img
            src='/images/toptoad.png'
            alt='cartoon trophy'
            className='Home_toptoadImage'
          ></img>
        </div>
        {/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        {/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        {/* ++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++++ */}
        {/* WHAT PLAYERS ARE SAYING */}
        <div className='Home_playerChatHeader centerText slackey'>
          What The Players Are Saying
        </div>
        <div className='Home_playerChat2017'>
          <img
            className='Home_playerChatImage2017'
            src='/images/2017/RobAndAndrew-webfriendly.jpg'
            alt='Rob Murray and Andrew Emerick'
          />
          <p className='centerText Home_playerChatCaptions'>
            "I won this thing in <a href='/report2017'>2017</a>"
          </p>
        </div>
        <div className='Home_playerChat2018'>
          <img
            className='Home_playerChatImage2018'
            src='/images/2018/Eugene-webfriendly.jpg'
            alt='Eugene Yee'
          />
          <p className='centerText Home_playerChatCaptions'>
            "I won this thing in <a href='/report2018'>2018</a>"
          </p>
        </div>
        <div className='Home_playerChat2019'>
          <img
            className='Home_playerChatImage2019'
            src='/images/2019/Randy-webfriendly.jpg'
            alt='Randy Buehler'
          />
          <p className='centerText Home_playerChatCaptions'>
            "I won this thing in <a href='/report2019'>2019</a>"
          </p>
        </div>
        <div className='Home_playerChat2020'>
          <img
            className='Home_playerChatImage2020'
            src='/images/2020/2020RodneySaying.jpg'
            alt='Rodney Bacigalupo'
          />
          <p className='centerText Home_playerChatCaptions'>
            "I won this thing in <a href='/report2020'>2020</a>"
          </p>
        </div>
        <div className='Home_playerChat2021'>
          <img
            className='Home_playerChatImage2021'
            src='/images/2021/2021AndrewSaying.jpg'
            alt='Andrew Emerick'
          />
          <p className='centerText Home_playerChatCaptions'>
            "I won this thing in <a href='/report2021'>2021</a>"
          </p>
        </div>
        <div className='Home_playerChat2022'>
          <img
            className='Home_playerChatImage2022'
            src='/images/2022/2022KyleSaying.jpg'
            alt='Kyle Smith'
          />
          <p className='centerText Home_playerChatCaptions'>
            "I won this thing in <a href='/report2022'>2022</a>"
          </p>
        </div>
        <div className='Home_playerChat2023'>
          <img
            className='Home_playerChatImage2023'
            src='/images/2023/2023Andrew2Saying.JPG'
            alt='Andrew Emerick'
          />
          <p className='centerText Home_playerChatCaptions'>
            "I won this thing again in <a href='/report2023'>2023</a>"
          </p>
        </div>
      </div>
    </div>
  );
};

export default home;
