const mediumGames = [
  { id: '3', gTitle: 'Alhambra' },
  { id: '25', gTitle: 'Istanbul' },
  { id: '30', gTitle: 'Luxor' },
  { id: '15', gTitle: 'Saint Petersburg' },
  { id: '17', gTitle: 'Splendor' },
  { id: '19', gTitle: 'Thurn & Taxis' },
  { id: '20', gTitle: 'Ticket to Ride' },
  { id: '38', gTitle: 'TTR World Maps' },
];

export default mediumGames;
