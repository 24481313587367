import './HamburgerButton.css';

const hamburgerButton = (props) => (
  <button className='hamburgerButton' onClick={props.click}>
    <div className='hamburgerButtonLine'></div>
    <div className='hamburgerButtonLine'></div>
    <div className='hamburgerButtonLine'></div>
  </button>
);

export default hamburgerButton;
