import longGames from './HardCodedData/longGames';
import mediumGames from './HardCodedData/mediumGames';
import shortGames from './HardCodedData/shortGames';

//this sets a starting point for
//the "columns" being used for state in GamePreferences.js

const columnsFromBackend = {
  1: {
    name: 'Long',
    items: longGames,
  },
  2: {
    name: 'Medium',
    items: mediumGames,
  },
  3: {
    name: 'Short',
    items: shortGames,
  },
  4: {
    name: 'Veto',
    items: [],
  },
};

export default columnsFromBackend;
