import './Report20XX.css';

const report2017 = () => {
  return (
    <div className='Report20XX_wrapper'>
      {/* THE CHAMP */}
      <div className='Report20XX_header'>The Champ in 2017</div>
      <div className='Report20XX_champPlaque'>
        <img
          className='Report20XX_champPlaquePic'
          src='/images/2017/Trophy2017-webfriendly.jpg'
          alt='2017 Plaque'
        />
      </div>
      <div className='Report20XX_champText'>
        In our inaugural contest, <strong>Rob Murray</strong> won things running
        away. He claimed victory in six out of the eight games he played over
        the course of the day, owning the "Top Toad" seat in nearly every game
        he played in. An impressive, dominating performance that set the bar for
        future gatherings!
      </div>
      <div className='Report20XX_champPic'>
        <img
          className='Report20XX_champPlayerPic'
          src='/images/2017/Rob2017-webfriendly.jpg'
          alt='Rob Murray'
        />
      </div>
      <div className='Report20XX_padding' />
      {/* THE TOADS */}
      <div className='Report20XX_header'>The Toads of 2017</div>
      <div className='Report20XX_toads'>
        <img
          className='Report20XX_toadsPic'
          src='/images/2017/FinalCrowd-webfriendly.jpg'
          alt='Gathering Around the Final Table'
        />
      </div>
      <ul className='Report20XX_toadsText'>
        <li>Rodney Bacigalupo (MD)</li>
        <li>Andrew Emerick (CT)</li>
        <li>Marcy Morelli (PA)</li>
        <li>Rob Murray (NJ)</li>
        <li>Amy Rule (MD)</li>
        <li>Norman Rule (MD)</li>
        <li>Antony Saccenti (MD)</li>
        <li>Donte Saccenti (MD)</li>
        <li>Scott Saccenti (MD)</li>
        <li>Kyle Smith (PA)</li>
        <li>Hilary Smith (PA)</li>
        <li>Eugene Yee (MD)</li>
      </ul>
      <div className='Report20XX_padding' />
      {/* THE GAMES */}
      <div className='Report20XX_header'>The Games of 2017</div>
      <ul className='Report20XX_gamesText'>
        <li>7 Wonders</li>
        <li>Agricola</li>
        <li>Alhambra</li>
        <li>Carcassonne</li>
        <li>Castles of Burgundy</li>
        <li>Concordia</li>
        <li>Egizia</li>
        <li>Great Western Trail</li>
        <li>Ingenious</li>
        <li>Lords of Waterdeep</li>
        <li>Power Grid</li>
        <li>Puerto Rico</li>
        <li>Ra</li>
        <li>Race for the Galaxy</li>
        <li>Saint Petersburg</li>
        <li>Settlers of Catan</li>
        <li>Splendor</li>
        <li>Stone Age</li>
        <li>Thurn & Taxis</li>
        <li>Ticket to Ride</li>
        <li>Vegas Showdown</li>
        <li>Village</li>
      </ul>
      <div className='Report20XX_games'>
        <img
          className='Report20XX_gamesPic'
          src='/images/2017/FamilyAndAmy-webfriendly.jpg'
          alt='The Saccentis and Amy Rule playing Ticket to Ride'
        />
      </div>
    </div>
  );
};

export default report2017;
