import './Report20XX.css';

const report2021 = () => {
  return (
    <div className='Report20XX_wrapper'>
      {/* THE CHAMP */}
      <div className='Report20XX_header'>Hoppin' Through the 'Rona in 2021</div>
      <div className='Report20XX_champPlaque'>
        <img
          className='Report20XX_champPlaquePic'
          src='/images/2021/2021Trophy.jpg'
          alt='2021 Plaque'
        />
      </div>
      <div className='Report20XX_champTextShrink'>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;While no one felt like we were past the Bad
          Times (and indeed we were not), 2021 saw a return to at least some bit
          of normalcy for The Toad. Missing some of our far-away crowd, we still
          managed to get back to a 16-player format, which works awfully well.
          We had an enjoyable long weekend, and another great run to an
          especially satisfying Final.
        </p>
        <p>
          Only one new Toad added to the 'Stool this year: Chris Yaure joins the
          family, while we also welcomed back Andrew, Chris and Jay, who had to
          miss the '20 Make-Up Toad.
        </p>
        <p>
          Chad posted updates to our Discord over the course of Sunday, you can
          watch the flow of the day from these pics. Antony fought his way
          through a tough start, and Rob faded a bit at the end. But Andrew,
          Chad and Jay held strong mostly from start to finish, with Dalton and
          Aaron just outside the T-shirt zone.
        </p>
        <p>
          So two first-time Finalists would need to be sized for sure. Andrew as
          Top Toad got to call his game, and surprised us with new-this-year
          Medici (we were all thinking Vegas, right?). It was a good call
          (perhaps he noticed Chris hurriedly teaching Chad the game, since it
          was on the short list of possibilities!).
        </p>
        <p>
          Andrew has made the Final every trip, but never broken through. In a
          close finish, he held off a charge by Antony to win the plaque. This
          is our fifth Top Toad, and I'm immensely pleased we have had five
          different winners over those years. Indeed, more attendees than not
          have managed a Finals appearance.
        </p>
        <p>Not, however, your humble host: The Shirtless One.</p>
      </div>
      <div className='Report20XX_champPic'>
        <img
          className='Report20XX_champPlayerPic'
          src='/images/2021/2021Champ.jpg'
          alt='Andrew Emerick displays plaque'
        />
      </div>
      <div className='Report20XX_padding' />
      {/* THE TOADS */}
      <div className='Report20XX_header'>The Toads of 2021</div>
      <div className='Report20XX_toads'>
        <img
          className='Report20XX_toadsPic'
          src='/images/2021/2021Group.jpg'
          alt='The 2021 Toads Group'
        />
      </div>
      <ul className='Report20XX_toadsText'>
        <li>Rodney Bacigalupo (AR)</li>
        <li>Aaron Blair (MD)</li>
        <li>Andrew Emerick (CT)</li>
        <li>Jay Fox (NJ)</li>
        <li>Rob Murray (NJ)</li>
        <li>Antony Saccenti (MD)</li>
        <li>Donte Saccenti (MD)</li>
        <li>Preston Saccenti (MD)</li>
        <li>Scott Saccenti (MD)</li>
        <li>Kyle Smith (PA)</li>
        <li>Dalton Versak (PA)</li>
        <li>Chad Weaver (PA)</li>
        <li>Chris Wildes (DC)</li>
        <li>Chris Yaure (PA)</li>
        <li>Joe Yaure (DE)</li>
        <li>Eugene Yee (MD)</li>
      </ul>
      <div className='Report20XX_padding' />
      {/* THE GAMES */}
      <div className='Report20XX_header'>The Games of 2021</div>
      <ul className='Report20XX_gamesText'>
        <li>7 Wonders</li>
        <li>Agricola</li>
        <li>Alhambra</li>
        <li>Carcassonne</li>
        <li>Castles of Burgundy</li>
        <li>Coloretto</li>
        <li>Egizia</li>
        <li>For Sale</li>
        <li>Great Western Trail</li>
        <li>Ingenious</li>
        <li>Istanbul</li>
        <li>Las Vegas</li>
        <li>Lords of Waterdeep</li>
        <li>Medici</li>
        <li>Power Grid</li>
        <li>Puerto Rico</li>
        <li>Ra</li>
        <li>Race for the Galaxy</li>
        <li>Saint Petersburg</li>
        <li>Splendor</li>
        <li>Stone Age</li>
        <li>Terraforming Mars</li>
        <li>Thurn & Taxis</li>
        <li>Ticket to Ride</li>
        <li>Vegas Showdown</li>
      </ul>
      <div className='Report20XX_games'>
        <img
          className='Report20XX_gamesPic'
          src='/images/2021/2021TeamTicket.jpg'
          alt='Antony and Preston Saccenti, Aaron Blair, Dalton Versak, Rodney Bacigalupo and Eugene Yee play Team Ticket to Ride'
        />
      </div>
      <div className='Report20XX_extraPics'>
        <img
          className='Report20XX_extraPic1'
          src='/images/2021/2021ChrisPoints.jpg'
          alt='Chris Wildes and Chad Weaver play Las Vegas'
        />
        <img
          className='Report20XX_extraPic2'
          src='/images/2021/2021SevenWonders.jpg'
          alt='Preston Saccenti, Jay Fox, Rob Murray and Kyle Smith play 7 Wonders'
        />
        <img
          className='Report20XX_extraPic3'
          src='/images/2021/2021LunchBreak.jpg'
          alt='The group takes a lunch break'
        />
        <img
          className='Report20XX_extraPic4'
          src='/images/2021/2021RoundOver.jpg'
          alt='A round of game concludes'
        />
        <img
          className='Report20XX_extraPic5'
          src='/images/2021/2021KyleSplendor.jpg'
          alt='Kyle Smith'
        />
        <img
          className='Report20XX_extraPic6'
          src='/images/2021/2021ChrisYaure.jpg'
          alt='Chris Yaure'
        />
        <img
          className='Report20XX_extraPic7'
          src='/images/2021/2021JayFox.jpg'
          alt='Jay Fox'
        />
        <img
          className='Report20XX_extraPic8'
          src='/images/2021/2021RobMurray.jpg'
          alt='Rob Murray'
        />
        <img
          className='Report20XX_extraPic9'
          src='/images/2021/2021Ingenious.jpg'
          alt='Chris Wildes, Aaron Blair, Chris Yaure and Joe Yaure play Ingenious'
        />
        <img
          className='Report20XX_extraPic10'
          src='/images/2021/2021ChickenCha.jpg'
          alt='Eugene Yee and Pria Saccenti play Chicken Cha Cha Cha'
        />
        <img
          className='Report20XX_extraPic11'
          src='/images/2021/2021RodneySwifter.jpg'
          alt='Rodney Bacigalupo helps get ready for the big day with a Swifter'
        />
        <img
          className='Report20XX_extraPic12'
          src='/images/2021/2021DaltonVersack.jpg'
          alt='Dalton Versak'
        />
        <img
          className='Report20XX_extraPic13'
          src='/images/2021/2021FourWheel.jpg'
          alt='Antony, Pria and Preston Saccenti, and Rodney Bacigalupo, four-wheeling during the PreCon'
        />
        <img
          className='Report20XX_extraPic14'
          src='/images/2021/2021BurgersRibs.jpg'
          alt='Burgers, ribs and onion rings, a lunch from earlier in the week'
        />
        <img
          className='Report20XX_extraPic15'
          src='/images/2021/2021PrestonPool.jpg'
          alt='Pria and Preston Saccenti, and Rodney Bacigalupo, swimming during the PreCon'
        />
        <img
          className='Report20XX_extraPic16'
          src='/images/2021/2021GroupGoof.jpg'
          alt='2021 Toads goof off'
        />
      </div>
    </div>
  );
};

export default report2021;
