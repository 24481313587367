import HamburgerButton from './HamburgerButton';

import './Banner.css';

const banner = (props) => {
  return (
    <header className='banner'>
      <nav className='banner_navigation'>
        <div className='banner_toggleButton'>
          <HamburgerButton click={props.hamburgerClicked} />
        </div>
        <a href='/'>
          <img
            className='toadPic'
            src='images/toptoad.png'
            alt='toad pic'
          ></img>
        </a>
        <div className='banner_logo'>
          <a href='/'>The Top Toads</a>
        </div>
        <div className='banner_spacer'></div>
        <div className='banner_navigation-items'>
          <ul>
            <li>
              <a href='/about'>About</a>
            </li>
            <li>
              <a href='/upcoming'>Upcoming</a>
            </li>
            <li>
              <a href='/stats'>Stats</a>
            </li>
            <li>
              <a href='/reports'>Annual Reports</a>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default banner;
