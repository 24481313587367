import './Stats.css';

const stats = () => {
  return (
    <div className='Stats_wrapper'>
      <div className='Stats_about'>
        <h1 className='Stats_header'>About The Stats</h1>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;Every game at The Toad awards some points. We
          tweak the formula a tiny bit from year-to-year, and longer games get a
          bit more consideration (and shorter games a bit less). But the idea
          roughly is something like this: 7 points for first place, 3 points for
          second, 1 point for third, 0 points for last. Thus winning is richly
          compensated, but every finish matters. In addition, players can begin
          the tournament with a small number of points, usually 1 point for each
          veto (of the three permitted) that they DON'T use.
        </p>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;It's worth noting that the four Finalists play
          an additional game versus the rest of the field, furthering their
          totals, and possibly exaggerating the actual lead they had over the
          others in the tournament.
        </p>
      </div>
      <div className='Stats_results'>
        <h1 className='Stats_header'>All-Time Results</h1>
        <div className='centerText Stats_nameList'>
          <ul>
            <li>
              <strong>Andrew Emerick (202)</strong>
            </li>
            <li>Antony Saccenti (179)</li>
            <li>Kyle Smith (175)</li>
            <li>Rob Murray (162)</li>
            <li>Eugene Yee (148)</li>
            <li>Donte Saccenti (143)</li>
            <li>Scott Saccenti (141)</li>
            <li>Rodney Bacigalupo (105)</li>
            <li>Dalton Versak (102)</li>
            <li>Chris Wildes (97)</li>
            <li>Joe Yaure (85)</li>
            <li>Chad Weaver (79)</li>
            <li>Marcy Morelli (78)</li>
            <li>Randy Buehler (74)</li>
            <li>Aaron Blair (69)</li>
            <li>Jay Fox (57)</li>
            <li>Preston Saccenti (55)</li>
            <li>Chris Yaure (49)</li>
            <li>Andy Latto (29)</li>
            <li>Hilary Smith (24)</li>
            <li>Sceadeau D'Tela (23)</li>
            <li>Eric Wrobel (22)</li>
            <li>Dave Rohde (19)</li>
            <li>Amy Rule (14)</li>
            <li>Norman Rule (8)</li>
          </ul>
        </div>
        <br />
      </div>
      <div className='Stats_2017'>
        <h1 className='Stats_header'>2017 Totals</h1>
        <div className='centerText Stats_nameList'>
          <ul>
            <li>
              <strong>Rob Murray (38)</strong>
            </li>
            <li>Andrew Emerick (25)</li>
            <li>Antony Saccenti (21)</li>
            <li>Eugene Yee (21)</li>
            <li>Donte Saccenti (18)</li>
            <li>Marcy Morelli (16)</li>
            <li>Scott Saccenti (14)</li>
            <li>Amy Rule (14)</li>
            <li>Kyle Smith (13)</li>
            <li>Hilary Smith (11)</li>
            <li>Rodney Bacigalupo (9)</li>
            <li>Norman Rule (8)</li>
          </ul>
        </div>
        <br />
      </div>
      <div className='Stats_2018'>
        <h1 className='Stats_header'>2018 Totals</h1>
        <div className='centerText Stats_nameList'>
          <ul>
            <li>
              <strong>Eugene Yee (34)</strong>
            </li>
            <li>Andrew Emerick (30)</li>
            <li>Donte Saccenti (28)</li>
            <li>Rob Murray (22)</li>
            <li>Andy Latto (21)</li>
            <li>Antony Saccenti (20)</li>
            <li>Kyle Smith (19)</li>
            <li>Scott Saccenti (19)</li>
            <li>Rodney Bacigalupo (19)</li>
            <li>Hilary Smith (13)</li>
            <li>Dave Rohde (9)</li>
            <li>Chris Wildes (7)</li>
          </ul>
        </div>
        <br />
      </div>
      <div className='Stats_2019'>
        <h1 className='Stats_header'>2019 Totals</h1>
        <div className='centerText Stats_nameList'>
          <ul>
            <li>
              <strong>Randy Buehler (40)</strong>
            </li>
            <li>Kyle Smith (36)</li>
            <li>Andrew Emerick (29)</li>
            <li>Sceadeau D'Tela (23)</li>
            <li>Jay Fox (23)</li>
            <li>Eric Wrobel (22)</li>
            <li>Scott Saccenti (22)</li>
            <li>Eugene Yee (19)</li>
            <li>Rodney Bacigalupo (18)</li>
            <li>Antony Saccenti (17)</li>
            <li>Chris Wildes (13)</li>
            <li>Rob Murray (12)</li>
            <li>Dalton Versak (10)</li>
            <li>Dave Rohde (10)</li>
            <li>Andy Latto (8)</li>
            <li>Marcy Morelli (7)</li>
          </ul>
        </div>
        <br />
      </div>
      <div className='Stats_2020'>
        <h1 className='Stats_header'>2020 Totals</h1>
        <div className='centerText Stats_nameList'>
          <ul>
            <li>
              <strong>Rodney Bacigalupo (34)</strong>
            </li>
            <li>Kyle Smith (36)</li>
            <li>Joe Yaure (25)</li>
            <li>Donte Saccenti (24)</li>
            <li>Aaron Blair (22)</li>
            <li>Antony Saccenti (20)</li>
            <li>Scott Saccenti (20)</li>
            <li>Eugene Yee (19)</li>
            <li>Chad Weaver (19)</li>
            <li>Dalton Versak (18)</li>
            <li>Rob Murray (17)</li>
            <li>Preston Saccenti (13)</li>
          </ul>
        </div>
        <br />
      </div>
      <div className='Stats_2021'>
        <h1 className='Stats_header'>2021 Totals</h1>
        <div className='centerText Stats_nameList'>
          <ul>
            <li>
              <strong>Andrew Emerick (44)</strong>
            </li>
            <li>Jay Fox (34)</li>
            <li>Antony Saccenti (33)</li>
            <li>Chad Weaver (27)</li>
            <li>Dalton Versak (26)</li>
            <li>Donte Saccenti (24)</li>
            <li>Aaron Blair (23)</li>
            <li>Chris Wildes (19)</li>
            <li>Kyle Smith (17)</li>
            <li>Rob Murray (16)</li>
            <li>Rodney Bacigalupo (16)</li>
            <li>Eugene Yee (15)</li>
            <li>Joe Yaure (13)</li>
            <li>Chris Yaure (11)</li>
            <li>Scott Saccenti (11)</li>
            <li>Preston Saccenti (7)</li>
          </ul>
        </div>
        <br />
      </div>
      <div className='Stats_2022'>
        <h1 className='Stats_header'>2022 Totals</h1>
        <div className='centerText Stats_nameList'>
          <ul>
            <li>
              <strong>Kyle Smith (38)</strong>
            </li>
            <li>Antony Saccenti (38)</li>
            <li>Marcy Morelli (36)</li>
            <li>Andrew Emerick (34)</li>
            <li>Scott Saccenti (30)</li>
            <li>Chris Wildes (27)</li>
            <li>Dalton Versak (26)</li>
            <li>Rob Murray (23)</li>
            <li>Eugene Yee (21)</li>
            <li>Chris Yaure (20)</li>
            <li>Joe Yaure (20)</li>
            <li>Donte Saccenti (20)</li>
            <li>Preston Saccenti (19)</li>
            <li>Aaron Blair (16)</li>
            <li>Chad Weaver (11)</li>
            <li>Rodney Bacigalupo (9)</li>
          </ul>
        </div>
        <br />
      </div>
      <div className='Stats_2023'>
        <h1 className='Stats_header'>2023 Totals</h1>
        <div className='centerText Stats_nameList'>
          <ul>
            <li>
              <strong>Andrew Emerick (40)</strong>
            </li>
            <li>Rob Murray (34)</li>
            <li>Randy Buehler (34)</li>
            <li>Chris Wildes (31)</li>
            <li>Antony Saccenti (30)</li>
            <li>Donte Saccenti (29)</li>
            <li>Joe Yaure (27)</li>
            <li>Scott Saccenti (25)</li>
            <li>Dalton Versak (22)</li>
            <li>Chad Weaver (22)</li>
            <li>Eugene Yee (19)</li>
            <li>Marcy Morelli (19)</li>
            <li>Chris Yaure (18)</li>
            <li>Kyle Smith (16)</li>
            <li>Preston Saccenti (16)</li>
            <li>Aaron Blair (8)</li>
          </ul>
        </div>
        <br />
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default stats;
