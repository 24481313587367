import './Report20XX.css';

const report2018 = () => {
  return (
    <div className='Report20XX_wrapper'>
      {/* THE CHAMP */}
      <div className='Report20XX_header'>The Champ in 2018</div>
      <div className='Report20XX_champPlaque'>
        <img
          className='Report20XX_champPlaquePic'
          src='/images/2018/Plaque2018.JPG'
          alt='2018 Plaque'
        />
      </div>
      <div className='Report20XX_champText'>
        We had a very different flow to the day in 2018 from the prior year. The
        leaderboard flip-flopped a bunch, and more players took more turns being
        the Top Toad at their table compared to the prior contest. And
        yet...when the dust settled...the Finals table looked much the same as
        it did in 2017 (with Donte replacing Antony as the token Saccenti Kid In
        The Final). <strong>Eugene Yee</strong>, who got off to an awful start
        early in the day, rallied to qualify as Top Toad going into the Final.
        And he got a big reward: with the last game coming off his ranked
        list--and no vetoes from the other three!--Vegas Showdown became the Top
        Toad Showdown for 2018. It's almost a specialty game for Eugene, and he
        saw it home to a championship win.
      </div>
      <div className='Report20XX_champPic'>
        <img
          className='Report20XX_champPlayerPic'
          src='/images/2018/Eugene-champ-webfriendly.jpg'
          alt='Eugene Yee'
        />
      </div>
      <div className='Report20XX_padding' />
      {/* THE TOADS */}
      <div className='Report20XX_header'>The Toads of 2018</div>
      <div className='Report20XX_toads'>
        <img
          className='Report20XX_toadsPic'
          src='/images/2018/VegasFinal-webfriendly.jpg'
          alt='Vegas Showdown at the Final Table'
        />
      </div>
      <ul className='Report20XX_toadsText'>
        <li>Rodney Bacigalupo (MD)</li>
        <li>Andrew Emerick (CT)</li>
        <li>Andy Latto (MA)</li>
        <li>Rob Murray (NJ)</li>
        <li>Dave Rohde (NC)</li>
        <li>Antony Saccenti (MD)</li>
        <li>Donte Saccenti (MD)</li>
        <li>Scott Saccenti (MD)</li>
        <li>Kyle Smith (PA)</li>
        <li>Hilary Smith (PA)</li>
        <li>Chris Wildes (DC)</li>
        <li>Eugene Yee (MD)</li>
      </ul>
      <div className='Report20XX_padding' />
      {/* THE GAMES */}
      <div className='Report20XX_header'>The Games of 2018</div>
      <ul className='Report20XX_gamesText'>
        <li>7 Wonders</li>
        <li>Agricola</li>
        <li>Alhambra</li>
        <li>Carcassonne</li>
        <li>Castles of Burgundy</li>
        <li>Egizia</li>
        <li>For Sale</li>
        <li>Great Western Trail</li>
        <li>Ingenious</li>
        <li>Lords of Waterdeep</li>
        <li>Power Grid</li>
        <li>Puerto Rico</li>
        <li>Ra</li>
        <li>Race for the Galaxy</li>
        <li>Saint Petersburg</li>
        <li>Settlers of Catan</li>
        <li>Splendor</li>
        <li>Stone Age</li>
        <li>Terraforming Mars</li>
        <li>Thurn & Taxis</li>
        <li>Ticket to Ride</li>
        <li>Vegas Showdown</li>
      </ul>
      <div className='Report20XX_games'>
        <img
          className='Report20XX_gamesPic'
          src='/images/2018/Vegas-webfriendly.jpg'
          alt='Rodney Bacigalupo, Andy Latto, Dave Rohde and Kyle Smith play Vegas Showdown'
        />
      </div>
    </div>
  );
};

export default report2018;
