import './Reports.css';

const reports = () => {
  return (
    <div className='Reports_wrapper'>
      <div className='Reports_header'>When Past We Met...</div>

      <div className='Reports_2017'>
        <a href='/report2017'>
          <img
            src='/images/2017/2017Finalists-webfriendly.jpg'
            alt='2017 Finalists'
            className='Reports_pics'
          ></img>
        </a>
        <div>
          <a href='/report2017'>2017 Report</a>
        </div>
      </div>

      <div className='Reports_2018'>
        <a href='/report2018'>
          <img
            src='/images/2018/DontePlusTheThree-webfriendly.jpg'
            alt='2018 Finalists'
            className='Reports_pics'
          ></img>
        </a>
        <div>
          <a href='/report2018'>2018 Report</a>
        </div>
      </div>

      <div className='Reports_2019'>
        <a href='/report2019'>
          <img
            src='/images/2019/Overhead-webfriendly.jpg'
            alt='2019 Finalists'
            className='Reports_pics'
          ></img>
        </a>
        <div>
          <a href='/report2019'>2019 Report</a>
        </div>
      </div>

      <div className='Reports_2020'>
        <a href='/report2020'>
          <img
            src='/images/2020/2020-FinalTable-web.jpg'
            alt='2020 Finalists'
            className='Reports_pics'
          ></img>
        </a>
        <div>
          <a href='/report2020'>2020 Report</a>
        </div>
      </div>

      <div className='Reports_2021'>
        <a href='/report2021'>
          <img
            src='/images/2021/2021LasVegasTable.jpg'
            alt='Chris Yaure, Donte Saccenti, Joe Yaure and Rodney Bacigalupo playing Las Vegas'
            className='Reports_pics'
          ></img>
        </a>
        <div>
          <a href='/report2021'>2021 Report</a>
        </div>
      </div>

      <div className='Reports_2022'>
        <a href='/report2022'>
          <img
            src='/images/2022/2022Report.jpg'
            alt='Three tables of players at Top Toad'
            className='Reports_pics'
          ></img>
        </a>
        <div>
          <a href='/report2022'>2022 Report</a>
        </div>
      </div>

      <div className='Reports_2023'>
        <a href='/report2023'>
          <img
            src='/images/2023/2023Report.jpg'
            alt='Three tables of players at Top Toad'
            className='Reports_pics'
          ></img>
        </a>
        <div>
          <a href='/report2023'>2023 Report</a>
        </div>
      </div>

      <br />
      <br />
      <br />
    </div>
  );
};

export default reports;
