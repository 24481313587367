import { useState } from 'react';
import { Route } from 'react-router-dom';

import Banner from './Navigation/Banner';
import SideDrawer from './Navigation/SideDrawer';
import Backdrop from './UI/Backdrop';
import Home from './PublicPages/Home';
import About from './PublicPages/About';
import Upcoming from './PublicPages/Upcoming';
import Stats from './PublicPages/Stats';
import Reports from './PublicPages/Reports';
import Report2017 from './PublicPages/ReportsByYear/Report2017';
import Report2018 from './PublicPages/ReportsByYear/Report2018';
import Report2019 from './PublicPages/ReportsByYear/Report2019';
import Report2020 from './PublicPages/ReportsByYear/Report2020';
import Report2021 from './PublicPages/ReportsByYear/Report2021';
import Report2022 from './PublicPages/ReportsByYear/Report2022';
import Report2023 from './PublicPages/ReportsByYear/Report2023';
import GamePreferences from './GamePreferences/GamePreferences';
import ThankYou from './GamePreferences/ThankYou';

import './App.css';

function App() {
  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);

  const hamburgerClickHandler = () => {
    setSideDrawerOpen(!sideDrawerOpen);
  };

  const backdropClickHandler = () => {
    setSideDrawerOpen(false);
  };

  const mainListClickHander = () => {
    setSideDrawerOpen(false);
  };

  let backdrop;

  if (sideDrawerOpen) {
    backdrop = <Backdrop clickBackdrop={backdropClickHandler} />;
  }

  return (
    <>
      <Banner hamburgerClicked={hamburgerClickHandler} />
      <SideDrawer
        showSideDrawer={sideDrawerOpen}
        clickMainList={mainListClickHander}
      />
      {backdrop}
      <div className='app_body'>
        <Route path='/' exact component={Home} />
        <Route path='/about' component={About} />
        <Route path='/upcoming' component={Upcoming} />
        <Route path='/stats' component={Stats} />
        <Route path='/reports' component={Reports} />
        <Route path='/report2017' component={Report2017} />
        <Route path='/report2018' component={Report2018} />
        <Route path='/report2019' component={Report2019} />
        <Route path='/report2020' component={Report2020} />
        <Route path='/report2021' component={Report2021} />
        <Route path='/report2022' component={Report2022} />
        <Route path='/report2023' component={Report2023} />
        <Route path='/GamePreferences' component={GamePreferences} />
        <Route path='/ThankYou' component={ThankYou} />
      </div>
    </>
  );
}

export default App;
