const shortGames = [
  { id: '2', gTitle: '7 Wonders' },
  { id: '4', gTitle: 'Carcassonne' },
  { id: '27', gTitle: 'Coloretto' },
  { id: '23', gTitle: 'For Sale' },
  { id: '37', gTitle: 'Incan Gold' },
  { id: '9', gTitle: 'Ingenious' },
  { id: '26', gTitle: 'Las Vegas' },
];

export default shortGames;
