import React from 'react';

const ThankYou = () => {
  return (
    <div>
      <p>Thank you, your preferences have been uploaded to the database.</p>
      <p>You're done!</p>
    </div>
  );
};

export default ThankYou;
