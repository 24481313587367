import './Report20XX.css';

const report2022 = () => {
  return (
    <div className='Report20XX_wrapper'>
      {/* THE CHAMP */}
      <div className='Report20XX_header'>Six Years Six Champions!</div>
      <div className='Report20XX_champPlaque'>
        <img
          className='Report20XX_champPlaquePic'
          src='/images/2022/2022Plaque.jpg'
          alt='2022 Plaque'
        />
      </div>
      <div className='Report20XX_champTextShrink'>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;It was a glorious year of "firsts" for the
          Toad in 2022, as sixteen familiar faces gathered for
          what--finally--felt like the Post-'Rona Era. Here's hoping! We were
          again short of our proper "big group" in 2022 due to a basement
          remodeling SNAFU (that is getting made right for '23).
        </p>
        <p>
          For the first time ever, every Toad was a returning Toad--we had no
          first-timers this go-round. And yet there were some wonderful "firsts"
          nonetheless. We started awarding a mug to the "Tadpole" (the Toad who
          finishes last), and in 2022, two players simultaneously became the
          first Toads to own both a Mug and a Shirt, as Marcy broke into the
          Final Four for her first time, and former Champ Rodney nosedived to
          the bottom of the pack. Rodney now holds the dubious (?) distinction
          as the only player to own both a Plaque and a Mug!
        </p>
        <p>
          It was a really great race to make the last spot in the Final this
          year, and your humble host, the Shirtless One, came up just ONE POINT
          short of finally breaking through. He was nipped in the end by Andrew,
          who continued his Finals streak yet again: he's made it every single
          time! Andrew is now just one shirt away from a Wardrobe-For-The-Week
          :)
        </p>
        <p>
          Marcy was the only first-time Finalist this year, but that does extend
          our streak of always, every time, seeing someone new make the Final.
          That's just outstanding.
        </p>
        <p>
          Speaking of Firsts, our Champion this year--Kyle Smith--could not be
          more deserving. He has perservered through some rough years, and some
          near-misses in his chase to the Top, and I think everyone in the room
          was pulling for him in 2022.{' '}
          <strong>SIX YEARS, SIX CHAMPIONS! </strong>How cool is that?!
        </p>
      </div>
      <div className='Report20XX_champPic'>
        <img
          className='Report20XX_champPlayerPic'
          src='/images/2022/2022Champ.jpg'
          alt='Kyle Smith displays plaque'
        />
      </div>
      <div className='Report20XX_padding' />
      {/* THE TOADS */}
      <div className='Report20XX_header'>The Toads of 2022</div>
      <div className='Report20XX_toads'>
        <img
          className='Report20XX_toadsPic'
          src='/images/2022/2022Group.jpg'
          alt='The 2022 Top Toads Group'
        />
      </div>
      <ul className='Report20XX_toadsText'>
        <li>Rodney Bacigalupo (AR)</li>
        <li>Aaron Blair (MD)</li>
        <li>Andrew Emerick (CT)</li>
        <li>Marcy Morelli (PA)</li>
        <li>Rob Murray (NJ)</li>
        <li>Antony Saccenti (MD)</li>
        <li>Donte Saccenti (MD)</li>
        <li>Preston Saccenti (MD)</li>
        <li>Scott Saccenti (MD)</li>
        <li>Kyle Smith (PA)</li>
        <li>Dalton Versak (PA)</li>
        <li>Chad Weaver (PA)</li>
        <li>Chris Wildes (DC)</li>
        <li>Chris Yaure (PA)</li>
        <li>Joe Yaure (DE)</li>
        <li>Eugene Yee (MD)</li>
      </ul>
      <div className='Report20XX_padding' />
      {/* THE GAMES */}
      <div className='Report20XX_header'>The Games of 2022</div>
      <ul className='Report20XX_gamesText'>
        <li>7 Wonders</li>
        <li>Alhambra</li>
        <li>Carcassonne</li>
        <li>Castles of Mad King Ludwig</li>
        <li>Chicago Express</li>
        <li>Coloretto</li>
        <li>Egizia</li>
        <li>For Sale</li>
        <li>Homesteaders</li>
        <li>Ingenious</li>
        <li>Istanbul</li>
        <li>Las Vegas</li>
        <li>Luxor</li>
        <li>Lords of Waterdeep</li>
        <li>Medici</li>
        <li>Navegador</li>
        <li>PARKS</li>
        <li>Puerto Rico</li>
        <li>Ra</li>
        <li>Race for the Galaxy</li>
        <li>Railways of the World</li>
        <li>Saint Petersburg</li>
        <li>Speicherstadt</li>
        <li>Splendor</li>
        <li>Stone Age</li>
        <li>Thurn & Taxis</li>
        <li>Ticket to Ride</li>
        <li>Vegas Showdown</li>
      </ul>
      <div className='Report20XX_games'>
        <img
          className='Report20XX_gamesPic'
          src='/images/2022/2022Games.jpg'
          alt='Wide shot of several games being played at Top Toad'
        />
      </div>
      <div className='Report20XX_extraPics'>
        <img
          className='Report20XX_extraPic1'
          src='/images/2022/2022Pria.jpg'
          alt='Dalton Versak, Joe Yaure and Pria Saccenti play Guillotine'
        />
        <img
          className='Report20XX_extraPic2'
          src='/images/2022/2022WarOfTheRing.jpg'
          alt='Preston Saccenti and Chris Yaure play War of the Ring'
        />
        <img
          className='Report20XX_extraPic3'
          src='/images/2022/2022Castles.jpg'
          alt='Rodney Bacigalupo, Andrew Emerick, Chris Wildes and Aaron Blair play The Castles of Mad King Ludwig'
        />
        <img
          className='Report20XX_extraPic4'
          src='/images/2022/2022Egizia.jpg'
          alt='Antony Saccenti, Aaron Blair, Preston Saccenti and Kyle Smith play Egizia'
        />
        <img
          className='Report20XX_extraPic5'
          src='/images/2022/2022JoeAndMarcy.jpg'
          alt='Joe Yaure and Marcy Morelli'
        />
        <img
          className='Report20XX_extraPic6'
          src='/images/2022/2022Antony.jpg'
          alt='Antony Saccenti'
        />
        <img
          className='Report20XX_extraPic7'
          src='/images/2022/2022Mars.jpg'
          alt='Kyle Smith, Chad Weaver, Aaron Blair and Donte Saccenti play Terraforming Mars'
        />
        <img
          className='Report20XX_extraPic8'
          src='/images/2022/2022Chris.jpg'
          alt='Chris Wildes'
        />
        <img
          className='Report20XX_extraPic9'
          src='/images/2022/2022Medici.jpg'
          alt='Over the shoulders of Andrew Emerick and Eugene Yee as the play Medici'
        />
        <img
          className='Report20XX_extraPic10'
          src='/images/2022/2022Thurn.jpg'
          alt='Kyle Smith, Dalton Versak, Hilary Smith and Preston Saccenti play Thurn and Taxis, while Pria Saccenti and Rodney Bacigalupo look on'
        />
        <img
          className='Report20XX_extraPic11'
          src='/images/2022/2022PriaHandstand.jpg'
          alt='Pria Saccenti demonstrates a handstand'
        />
        <img
          className='Report20XX_extraPic12'
          src='/images/2022/2022KyleAndHilary.jpg'
          alt='Kyle and Hilary Smith play Saint Petersburg'
        />
        <img
          className='Report20XX_extraPic13'
          src='/images/2022/2022Guillotine.jpg'
          alt='Antony Saccenti, Dalton Versak, Rodney Bacigalupo and Pria Saccenti play Guillotine'
        />
        <img
          className='Report20XX_extraPic14'
          src='/images/2022/2022Finalists.jpg'
          alt='The 2023 Top Toad Finalists, Kyle Smith, Andrew Emerick, Antony Saccenti and Marcy Morelli'
        />
      </div>
    </div>
  );
};

export default report2022;
