import './Report20XX.css';

const report2020 = () => {
  return (
    <div className='Report20XX_wrapper'>
      {/* THE CHAMP */}
      <div className='Report20XX_header'>The Champ in 2020</div>
      <div className='Report20XX_champPlaque'>
        <img
          className='Report20XX_champPlaquePic'
          src='/images/2020/Plaque2020-web.jpg'
          alt='2020 Plaque'
        />
      </div>
      <div className='Report20XX_champTextShrink'>
        <p>
          &nbsp;&nbsp;&nbsp;&nbsp;This was an interesting year to be sure. The
          'Rona pushed our tournament out of the traditional Memorial Day
          weekend slot into November. And we missed many of our Toads that
          couldn't make the trip because of the Bad Times.
        </p>
        <p>
          Those that braved the storm were treated to probably our most relaxing
          outing to date. It was a welcome relief from the draught to see old
          friends and get to play over the long weekend.
        </p>
        <p>
          I've upped my culinary game a bit as well, with all the down time, and
          hopefully it showed in the Eats. One new tradition was born: the
          Night-Before Roast Turkey feast :)
        </p>
        <p>
          We welcomed a few new Toads to the Toadstool this year: Aaron Blair,
          Chad Weaver and Joe Yaure made impressive debuts, with Aaron missing
          the Final by one point, and Joe winning a shirt on this very first
          outing--so, so unfair to the long-suffering. Preston Saccenti also
          stepped in to pinch-hit for some missing Toads, and held his own quite
          well for the first half of the day before fading a bit down the
          stretch.
        </p>
        <p>
          For a second year in a row, Kyle led the group heading into the Final,
          and for the second year picked Egizia off his eligible list. But
          Rodney prevailed in the penultimate game, besting Kyle by a single
          point after adjusting for the bid. You'll see Rodney in a number of
          Final Table photos over the years, watching the last game. This year,
          they got to watch him.
        </p>
      </div>
      <div className='Report20XX_champPic'>
        <img
          className='Report20XX_champPlayerPic'
          src='/images/2020/Rodney-champ-web.jpg'
          alt='Rodney Bacigalupo displays plaque'
        />
      </div>
      <div className='Report20XX_padding' />
      {/* THE TOADS */}
      <div className='Report20XX_header'>The Toads of 2020</div>
      <div className='Report20XX_toads'>
        <img
          className='Report20XX_toadsPic'
          src='/images/2020/2020-groupgoof-web.jpg'
          alt='The 2020 Toads Group'
        />
      </div>
      <ul className='Report20XX_toadsText'>
        <li>Rodney Bacigalupo (MD)</li>
        <li>Aaron Blair (MD)</li>
        <li>Rob Murray (NJ)</li>
        <li>Antony Saccenti (MD)</li>
        <li>Donte Saccenti (MD)</li>
        <li>Preston Saccenti (MD)</li>
        <li>Scott Saccenti (MD)</li>
        <li>Kyle Smith (PA)</li>
        <li>Dalton Versak (PA)</li>
        <li>Joe Yaure (PA)</li>
        <li>Chad Weaver (PA)</li>
        <li>Eugene Yee (MD)</li>
      </ul>
      <div className='Report20XX_padding' />
      {/* THE GAMES */}
      <div className='Report20XX_header'>The Games of 2020</div>
      <ul className='Report20XX_gamesText'>
        <li>7 Wonders</li>
        <li>Agricola</li>
        <li>Alhambra</li>
        <li>Carcassonne</li>
        <li>Castles of Burgundy</li>
        <li>Coloretto</li>
        <li>Egizia</li>
        <li>For Sale</li>
        <li>Great Western Trail</li>
        <li>Ingenious</li>
        <li>Istanbul</li>
        <li>Las Vegas</li>
        <li>Lords of Waterdeep</li>
        <li>Power Grid</li>
        <li>Puerto Rico</li>
        <li>Ra</li>
        <li>Race for the Galaxy</li>
        <li>Saint Petersburg</li>
        <li>Settlers of Catan</li>
        <li>Splendor</li>
        <li>Stone Age</li>
        <li>Terraforming Mars</li>
        <li>Thurn & Taxis</li>
        <li>Ticket to Ride</li>
        <li>Vegas Showdown</li>
      </ul>
      <div className='Report20XX_games'>
        <img
          className='Report20XX_gamesPic'
          src='/images/2020/2020Ticket-crop-web.jpg'
          alt='Eugene Yee, Joe Yaure, Aaron Blair and Preston Saccenti play Ticket to Ride'
        />
      </div>
      <div className='Report20XX_extraPics'>
        <img
          className='Report20XX_extraPic1'
          src='/images/2020/2020-FinalTable-group-web.jpg'
          alt='Turkey'
        />
        <img
          className='Report20XX_extraPic2'
          src='/images/2020/2020Alhambra-web.jpg'
          alt='Turkey'
        />
        <img
          className='Report20XX_extraPic3'
          src='/images/2020/2020RobUpsideDown-web.jpg'
          alt='Turkey'
        />
        <img
          className='Report20XX_extraPic4'
          src='/images/2020/2020TurkeyDinner-web.jpg'
          alt='Turkey'
        />
        <img
          className='Report20XX_extraPic5'
          src='/images/2020/2020Splendor-web.jpg'
          alt='Eugene Yee, Antony Saccenti, Preston Saccenti and Donte Saccenti play Splendor as Rodney Bacigalupo looks on'
        />
      </div>
    </div>
  );
};

export default report2020;
