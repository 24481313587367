import './Upcoming.css';

const upcoming = () => {
  return (
    <div className='Upcoming_wrapper'>
      {/* ---BANNER--- */}
      <div className='Upcoming_banner'>
        <h1 className='Upcoming_header'>2024 Top Toads Schedule</h1>
        <p className='centerText slackey'>
          Thursday, May 23 through Saturday, May 25
          <br />
          All-Day Open Gaming
          <br />
          <br />
          Sunday, May 26
          <br />
          <span className='Upcoming_biggerText'>Top Toads Tournament Day</span>
          <br />
          8:00am -- Breakfast
          <br />
          9:00am -- Tournament games begin
        </p>
        <div className='centerText'>
          <img
            className='Upcoming_antandPic'
            src='/images/2017/AntonyAndAndrew-webfriendly.jpg'
            alt='Antony and Andrew'
          />
          <p className='centerText slackey'>
            Monday, May 27
            <br />
            Early-Day Open Gaming
          </p>
        </div>
      </div>

      {/* ---INSTRUCTIONS--- */}
      <div className='Upcoming_toadTips'>
        <h1 className='Upcoming_header'>Toad Tips</h1>
        <p>
          Flying in? The closest airports are Baltimore (BWI) and Philadelphia
          (PHL), with BWI being the most convenient, and usually least-expensive
          option. We will get you to-and-from the airport, just coordinate with
          us on the times.
        </p>
        <p>
          We'll use this area to communicate details about the upcoming event.
          More details to come of course, but here's a rough outline of some
          important dates...
        </p>
        <p>Early February: Initial attendance query</p>
        <p>
          <em>
            <strong>
              March 1: Need final commitments on expected attendance
            </strong>
          </em>
        </p>
        <p>April 11: Game preferences rank/veto solicited</p>
        <p>
          <em>
            <strong>April 18: Game Preferences Rank/Veto Due</strong>
          </em>
        </p>
      </div>

      {/* ---THE TOADS--- */}
      <div className='Upcoming_theToads'>
        <h1 className='Upcoming_header'>The Toads of 2024</h1>
        <div className='Upcoming_theToads_nameList'>
          <ul>
            <li>
              <strong>Aaron Blair (MD)</strong> <em>Thu-Mon</em>
            </li>
            <li>
              <strong>Andrew Emerick (CT)</strong> <em>Thu-Mon</em>
            </li>
            <li>
              <strong>Antony Saccenti (MD)</strong> <em>Thu-Mon</em>
            </li>
            <li>
              <strong>Chad Weaver (PA)</strong> <em>Thu-Sun</em>
            </li>
            <li>
              <strong>Dalton Versak (PA)</strong> <em>Thu-Mon</em>
            </li>
            <li>
              <strong>Donte Saccenti (MD)</strong> <em>Thu-Mon</em>
            </li>
            <li>
              <strong>Preston Saccenti (MD)</strong> <em>Thu-Mon</em>
            </li>
            <li>
              <strong>Randy Buehler (WA)</strong> <em>Thu-Mon</em>
            </li>
            <li>
              <strong>Rodney Bacigalupo (AR)</strong> <em>Thu-Mon</em>
            </li>
            <li>
              <strong>Scott Saccenti (MD)</strong> <em>Thu-Mon</em>
            </li>
            <li>
              <strong>Andy Latto (MA)</strong> <em>Fri-Mon</em>
            </li>
            <li>
              <strong>Joe Yaure (MD)</strong> <em>Fri-Mon?</em>
            </li>
            <li>
              <strong>Eugene Yee (MD)</strong> <em>Fri-Mon</em>
            </li>
            <li>
              <strong>Christy Applegate (MD)</strong> <em>Fri-Sun</em>
            </li>
            <li>
              <strong>Kyle Smith (PA)</strong> <em>Fri?-Sun</em>
            </li>
            <li>
              <strong>Rob Murray (NJ)</strong> <em>Sat-Mon?</em>
            </li>
            <li>
              <strong>Chris Wildes (DC)</strong> <em>Sun</em>
            </li>
            <li>
              <strong>Henry Dove (MD)</strong> <em>Sun</em>
            </li>
            <li>
              <strong>Marcy Morelli (PA)</strong> <em>Sun</em>
            </li>
          </ul>
        </div>
      </div>

      {/* ---THE GAMES--- */}
      <div className='Upcoming_theGames'>
        <h1 className='Upcoming_header'>The Games of 2024</h1>
        <h4>
          <strong>SHORT</strong>
        </h4>
        <ul>
          <li>7 Wonders</li>
          <li>Carcassonne</li>
          <li>Coloretto</li>
          <li>For Sale</li>
          <li>Incan Gold</li>
          <li>Ingenious</li>
          <li>Las Vegas</li>
        </ul>
        <br />
        <h4>
          <strong>MEDIUM</strong>
        </h4>
        <ul>
          <li>Alhambra</li>
          <li>Istanbul</li>
          <li>Luxor</li>
          <li>Saint Petersburg</li>
          <li>Splendor</li>
          <li>Thurn &amp; Taxis</li>
          <li>Ticket to Ride</li>
          <li>Ticket to Ride: World Maps</li>
        </ul>
        <br />
        <h4>
          <strong>LONG</strong>
        </h4>
        <ul>
          <li>Castles of Mad King Ludwig</li>
          <li>Egizia</li>
          <li>Medici</li>
          <li>Puerto Rico</li>
          <li>Ra</li>
          <li>Stone Age</li>
          <li>Vegas Showdown</li>
        </ul>
      </div>

      {/* ---THE EATS--- */}
      <div className='Upcoming_theEats'>
        <div className='Upcoming_theEats_header'>
          <h1 className='Upcoming_header'>The Eats of 2024</h1>
        </div>
        <div className='Upcoming_theEats_day1'>
          <h4>
            <strong>THURSDAY</strong>
          </h4>
          <ul>
            <li>
              <strong>
                <em>Breakfast (9:30 to 10:00)</em>
              </strong>
            </li>
            <li>Yogurt</li>
            <li>Fruit</li>
            <li>Granola</li>
            <li>-----</li>
            <li>
              <strong>
                <em>Lunch (1:30 to 3:00)</em>
              </strong>
            </li>
            <li>Brats with Peppers and Onions</li>
            <li>Hot Dogs / Chili Dogs</li>
            <li>Red Potato Salad</li>
            <li>Tomato Bisque</li>
            <li>-----</li>
            <li>
              <strong>
                <em>Dinner (7:00 to 8:30)</em>
              </strong>
            </li>
            <li>Broccoli & Cheese Soup</li>
            <li>Irish Shepherd's Pie</li>
            <li>-----</li>
            <li>
              <strong>
                <em>Dessert (9:15)</em>
              </strong>
            </li>
            <li>Cheesecake</li>
            <li>Ice Cream</li>
          </ul>
        </div>
        <div className='Upcoming_theEats_day2'>
          <h4>
            <strong>FRIDAY</strong>
          </h4>
          <ul>
            <li>
              <strong>
                <em>Breakfast (8:30 to 9:30)</em>
              </strong>
            </li>
            <li>Pancakes</li>
            <li>Sausage</li>
            <li>Scrambled eggs</li>
            <li>Hash browns</li>
            <li>-----</li>
            <li>
              <strong>
                <em>Lunch (1:30 to 3:00)</em>
              </strong>
            </li>
            <li>Seafood Bisque</li>
            <li>Peanut Chicken Red Thai Curry</li>
            <li>Jasmine rice</li>
            <li>-----</li>
            <li>
              <strong>
                <em>Dinner (7:00 to 8:30)</em>
              </strong>
            </li>
            <li>Pasta Bolognese</li>
            <li>Lasagna</li>
            <li>Garden Salad</li>
            <li>Garlic Bread</li>
            <li>-----</li>
            <li>
              <strong>
                <em>Dessert (9:15)</em>
              </strong>
            </li>
            <li>Ice Cream</li>
          </ul>
        </div>
        <div className='Upcoming_theEats_day3'>
          <h4>
            <strong>SATURDAY</strong>
          </h4>

          <ul>
            <li>
              <strong>
                <em>Breakfast (8:30 to 9:30)</em>
              </strong>
            </li>
            <li>Bacon, Egg & Cheese McMuffins</li>
            <li>Donuts, Muffins &amp; Pastries</li>
            <li>-----</li>
            <li>
              <strong>
                <em>Lunch (1:30 to 3:00)</em>
              </strong>
            </li>
            <li>Taco Bar</li>
            <li>Beans, Rice</li>
            <li>Nachos</li>
            <li>-----</li>
            <li>
              <strong>
                <em>Dinner (7:00 to 8:30)</em>
              </strong>
            </li>
            <li>Roast Turkey</li>
            <li>Stuffing</li>
            <li>Mashed Potatoes</li>
            <li>Honey Garlic Glazed Carrots</li>
            <li>Broccoli & Cheese Casserole</li>
            <li>Sweet Potato Casserole</li>
            <li>Corn &amp; Peas</li>
            <li>-----</li>
            <li>
              <strong>
                <em>Dessert (9:15)</em>
              </strong>
            </li>
            <li>Caramel Apple Pie</li>
            <li>Chocolate Pecan Pie</li>
            <li>Ice Cream</li>
          </ul>
        </div>
        <div className='Upcoming_theEats_day4'>
          <h4>
            <strong>SUNDAY</strong>
          </h4>
          <ul>
            <li>
              <strong>
                <em>Breakfast (7:30 to 9:00)</em>
              </strong>
            </li>
            <li>Country Sausage Gravy &amp; Biscuits</li>
            <li>Made-to-order omelletes</li>
            <li>Last order in at 8:40am!</li>
            <li>Yogurt</li>
            <li>Fruit</li>
            <li>Granola</li>
            <li>Donuts</li>
            <li>Muffins</li>
            <li>-----</li>
            <li>
              <strong>
                <em>Lunch</em>
              </strong>
            </li>
            <li>Fried Chicken</li>
            <li>Macaroni &amp; Cheese</li>
            <li>-----</li>
            <li>
              <strong>
                <em>Dinner</em>
              </strong>
            </li>
            <li>Whatever the wife wants to make</li>
            <li>-----</li>
            <li>
              <strong>
                <em>Dessert</em>
              </strong>
            </li>
            <li>Whatever the daughters want to make</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default upcoming;
