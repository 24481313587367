import './Report20XX.css';

const report2019 = () => {
  return (
    <div className='Report20XX_wrapper'>
      {/* THE CHAMP */}
      <div className='Report20XX_header'>The Champ in 2019</div>
      <div className='Report20XX_champPlaque'>
        <img
          className='Report20XX_champPlaquePic'
          src='/images/2019/Plaque2019.JPG'
          alt='2019 Plaque'
        />
      </div>
      <div className='Report20XX_champTextShrink'>
        <p>
          There were big changes to our little tournament in 2019. To start, we
          expanded the field of players from twelve to sixteen, which went
          really well--there is a great competitive symmetry to
          four-tables-of-four. We welcomed back Marcy Morelli, a former
          contender, along with five new faces: Dalton, Eric, Jay, Randy,
          Sceadeau. The newbies made a huge splash in our pool, securing four of
          the top six spots by the end!
        </p>
        <p>
          We also tweaked the basic formula for choosing games, turning the
          process on its head. Starting this year, the Tadpole at the table
          (whoever was doing the worst at the moment) got preference on the
          game-selection algorithm. This went amazingly well. It's kind of a
          subtle change, but it goes a long way to making sure a more diverse
          set of games gets played, and that in the end, the bragging rights are
          all the better won for having done so on your opponents' field of
          play.
        </p>
        <p>
          It all came down to an amazing Final between four familiar players at
          a very familiar game. It's hard to believe Egizia hadn't gotten played
          in the initial seven rounds, but there it was available for the
          penultimate game. Kudos to newest champ <strong>Randy Buehler</strong>{' '}
          and the entire bunch for another wonderful weekend.
        </p>
      </div>
      <div className='Report20XX_champPic'>
        <img
          className='Report20XX_champPlayerPic'
          src='/images/2019/Randy-champ-webfriendly.jpg'
          alt='Randy Buehler displays plaque'
        />
      </div>
      <div className='Report20XX_padding' />
      {/* THE TOADS */}
      <div className='Report20XX_header'>The Toads of 2019</div>
      <div className='Report20XX_toads'>
        <img
          className='Report20XX_toadsPic'
          src='/images/2019/toadgroup2019.jpg'
          alt='The 2019 Toads Group'
        />
      </div>
      <ul className='Report20XX_toadsText'>
        <li>Rodney Bacigalupo (MD)</li>
        <li>Randy Buehler (WA)</li>
        <li>Sceadeau D'Tela (NC)</li>
        <li>Andrew Emerick (CT)</li>
        <li>Jay Fox (NJ)</li>
        <li>Andy Latto (MA)</li>
        <li>Marcy Morelli (PA)</li>
        <li>Rob Murray (NJ)</li>
        <li>Dave Rohde (NC)</li>
        <li>Antony Saccenti (MD)</li>
        <li>Scott Saccenti (MD)</li>
        <li>Kyle Smith (PA)</li>
        <li>Dalton Versak (PA)</li>
        <li>Chris Wildes (DC)</li>
        <li>Eric Wrobel (MD)</li>
        <li>Eugene Yee (MD)</li>
      </ul>
      <div className='Report20XX_padding' />
      {/* THE GAMES */}
      <div className='Report20XX_header'>The Games of 2019</div>
      <ul className='Report20XX_gamesText'>
        <li>7 Wonders</li>
        <li>Agricola</li>
        <li>Alhambra</li>
        <li>Carcassonne</li>
        <li>Castles of Burgundy</li>
        <li>Egizia</li>
        <li>For Sale</li>
        <li>Great Western Trail</li>
        <li>Ingenious</li>
        <li>Lords of Waterdeep</li>
        <li>Power Grid</li>
        <li>Puerto Rico</li>
        <li>Ra</li>
        <li>Race for the Galaxy</li>
        <li>Saint Petersburg</li>
        <li>Splendor</li>
        <li>Stone Age</li>
        <li>Terraforming Mars</li>
        <li>Thurn & Taxis</li>
        <li>Ticket to Ride</li>
        <li>Vegas Showdown</li>
      </ul>
      <div className='Report20XX_games'>
        <img
          className='Report20XX_gamesPic'
          src='/images/2019/Castles-webfriendly.jpg'
          alt='Eric Wrobel, Dave Rohde, Antony and Scott Saccenti play Castles of Burgundy'
        />
      </div>
    </div>
  );
};

export default report2019;
